import React from "react";
import PropTypes from "prop-types";

import { TextBanner } from "../../components/molecules/TextBanner";
import { LayoutBox } from "../../components/atoms/LayoutBox";
import { Testimonial } from "../../components/molecules/Testimonial";

export const TestimonialsPageTemplate = ({
  title,
  testimonials = [{ quote: "", author: "" }],
}) => {
  return (
    <div>
      <TextBanner title={title} />
      <LayoutBox>
        <div className="columns is-multiline">
          {testimonials.map(({ quote, author }, idx) => (
            <div className="column is-one-third">
              <Testimonial key={idx} quote={quote} author={author} />
            </div>
          ))}
        </div>
      </LayoutBox>
    </div>
  );
};

TestimonialsPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  testimonials: PropTypes.array,
};
