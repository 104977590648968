import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { TestimonialsPageTemplate } from "./TestimonialsPageTemplate";

const TestimonialsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout
      title={frontmatter.meta_title}
      description={frontmatter.meta_description}
    >
      <TestimonialsPageTemplate
        title={frontmatter.title}
        testimonials={frontmatter.testimonials.filter((t) => t.featured)}
      />
    </Layout>
  );
};

TestimonialsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TestimonialsPage;

export const TestimonialsPageQuery = graphql`
  query TestimonialsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        testimonials {
          author
          quote
          featured
        }
      }
    }
  }
`;
